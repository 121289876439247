@use 'sass:map';
@use '@angular/material' as mat;

//$color-config:    mat.get-color-config($theme);
//$primary-palette: map.get($color-config, 'primary');

$font-stack: Open Sans, Helvetika;

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$cws-yellow: (
  50 : #fffae0,
  100 : #fff2b3,
  200 : #ffea80,
  300 : #ffe24d,
  400 : #ffdb26,
  500 : #ffd500,
  600 : #ffd000,
  700 : #ffca00,
  800 : #ffc400,
  900 : #ffba00,
  A100 : #ffffff,
  A200 : #fffbf2,
  A400 : #ffebbf,
  A700 : #ffe4a6,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cws-blue: (
  50 : #e1f2fa,
  100 : #b3dff2,
  200 : #80caea,
  300 : #4db5e2,
  400 : #27a5db,
  500 : #0195d5,
  600 : #018dd0,
  700 : #0182ca,
  800 : #0178c4,
  900 : #0067ba,
  A100 : #e3f1ff,
  A200 : #b0d7ff,
  A400 : #7dbdff,
  A700 : #63b0ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cws-blue-dark: (
  50 : #e1e3ea,
  100 : #b3bac9,
  200 : #808ca6,
  300 : #4d5d82,
  400 : #273b67,
  500 : #01184c,
  600 : #011545,
  700 : #01113c,
  800 : #010e33,
  900 : #000824,
  A100 : #5f6eff,
  A200 : #2c3fff,
  A400 : #0017f8,
  A700 : #0014df,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$cws-dark-yellow: (
  50 : #fff7e0,
  100 : #ffeab3,
  200 : #ffdd80,
  300 : #ffcf4d,
  400 : #ffc426,
  500 : #ffba00,
  600 : #ffb300,
  700 : #ffab00,
  800 : #ffa300,
  900 : #ff9400,
  A100 : #ffffff,
  A200 : #fff9f2,
  A400 : #ffe1bf,
  A700 : #ffd5a6,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$cws-green: (
  50 : #e3f1ea,
  100 : #badbcc,
  200 : #8cc3aa,
  300 : #5eab87,
  400 : #3c996e,
  500 : #198754,
  600 : #167f4d,
  700 : #127443,
  800 : #0e6a3a,
  900 : #085729,
  A100 : #8bffb5,
  A200 : #58ff94,
  A400 : #25ff74,
  A700 : #0bff63,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);




$p-yellow: mat.define-palette($cws-yellow);
$p-yellow-dark: mat.define-palette($cws-dark-yellow);
$p-blue: mat.define-palette($cws-blue);
$p-blue-dark: mat.define-palette($cws-blue-dark);
$p-green: mat.define-palette($cws-green);

$c-yellow-lt: mat.get-color-from-palette($p-yellow, 300);
$c-blue-lt: mat.get-color-from-palette($p-blue, 300);
$c-blue-dark-lt: mat.get-color-from-palette($p-blue-dark, 300);
$c-yellow-dark-lt: mat.get-color-from-palette($p-yellow-dark, 300);
$c-green-lt: mat.get-color-from-palette($p-green, 300);

$c-yellow: mat.get-color-from-palette($p-yellow, 500);
$c-blue: mat.get-color-from-palette($p-blue);
$c-blue-dark: mat.get-color-from-palette($p-blue-dark, 500);
$c-yellow-dark: mat.get-color-from-palette($p-yellow-dark, 500);
$c-green: mat.get-color-from-palette($p-green, 500);

$c-yellow-dk: mat.get-color-from-palette($p-yellow, 900);
$c-blue-dk: mat.get-color-from-palette($p-blue-dark, 700);
$c-blue-dark-dk: mat.get-color-from-palette($p-blue-dark, 700);
$c-yellow-dark-dk: mat.get-color-from-palette($p-yellow-dark, 700);
$c-green-dk: mat.get-color-from-palette($p-green, 700);