.blog img{
    height: auto;
    width: 80%;
    display: block;
    margin: 25px auto;
}

.blog hr{
    width: 80%;
    opacity: 50%;
    margin: 25px auto;
}

.blog button{
    background-image: linear-gradient(to bottom right, #0CA9C4, #56ECF3);
    border-radius: 10px;
}

.blog ul{
    margin: 20px auto;
}

.blog-contact-banner{
    background-color: #2A2F33;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
}

.blog-contact-banner i{
    height: auto;
    width: 30%;
    display: inline-block;
    margin: 25px;
}

