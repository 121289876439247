@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "bootstrap/dist/css/bootstrap.css";
@import "_variables";
@import "ngx-toastr/toastr";
@import "@aws-amplify/ui-angular/theme.css";
@import "mixins";
@import "blog";
@import "theme";

.c-yellow {
  color: $c-yellow !important;
}

.c-yellow-lt {
  color: $c-yellow-lt !important;
}

.c-yellow-dk {
  color: $c-yellow-dk !important;
}

.c-blue {
  color: $c-blue !important;
}

.c-blue-lt {
  color: $c-blue-lt !important;
}

.c-blue-dk {
  color: $c-blue-dk !important;
}

.c-blue-dark {
  color: $c-blue-dark !important;
}

.c-blue-dark-lt {
  color: $c-blue-dark-lt !important;
}

.c-blue-dark-dk {
  color: $c-blue-dark-dk !important;
}

.text-white {
  color: white !important;
}

.bg-white {
  background-color: white !important;
}

.white-bk {
  background-color: white !important;
}

.c-yellow-bk {
  background-color: $c-yellow !important;
}

.c-yellow-lt-bk {
  background-color: $c-yellow-lt !important;
}

.c-yellow-dk-bk {
  background-color: $c-yellow-dark-dk !important;
}

.c-blue-bk {
  background-color: $c-blue !important;
}

.c-blue-lt-bk {
  background-color: $c-blue-lt !important;
}

.c-blue-dk-bk {
  background-color: $c-blue-dk !important;
}

.c-blue-dark-bk {
  background-color: $c-blue-dark !important;
}

.c-blue-dark-lt-bk {
  background-color: $c-blue-dark-lt !important;
}

.c-blue-dark-dk-bk {
  background-color: $c-blue-dark-dk !important;
}

.c-yellow-fl {
  fill: $c-yellow !important;
}

.c-yellow-lt-fl {
  fill: $c-yellow-lt !important;
}

.c-yellow-dk-fl {
  fill: $c-yellow-dk !important;
}

.c-blue-fl {
  fill: $c-blue !important;
}

.c-blue-lt-fl {
  fill: $c-blue-lt !important;
}

.c-blue-dk-fl {
  fill: $c-blue-dk !important;
}

html,
body {
  font-size: 100%; // 1rem = 16px
  height: 100%;
}

hr {
  border: 1px solid $c-blue-dark-lt;
}

#page {
  min-height: 100%;
}

#main {
  overflow: auto;
  padding-bottom: 242px;
}

#footer {
  position: relative;
  height: 242px;
  margin-top: -242px;
}

a,
a:visited {
  color: $c-blue !important;
  text-decoration: none;
}

a:hover,
.c-blue:hover {
  color: $c-blue-lt !important;
  text-decoration: none;
}

.cursor {
  cursor: pointer;
}

.base-color {
  background-color: #2e2e2e;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.pre-wrap {
  white-space: pre-wrap;
}

h1 {
  padding-bottom: 0em;
  font-size: 2.7em !important;
  color: $c-yellow-dark-dk;
  font-weight: 700 !important;
}

h2 {
  padding-bottom: 0em;
  font-size: 2em;
  color: $c-yellow-dark-dk;
}

h3 {
  padding-bottom: 0em;
  font-size: 1.6em;
  color: $c-blue-dark-lt;
}

h4 {
  padding-bottom: 0em;
  font-size: 2.4em;
  color: $c-yellow-dark-lt;
}

h5 {
  padding-bottom: 0em;
  font-size: 2.4em;
  color: $c-yellow-dark-lt;
}

p {
  color: #666;
}

.content-page {
  background-color: #e9e9e9;
}
